import axios from "axios";
import { getSession } from "next-auth/react";
import { setIsLoggedSync } from "../../utils/storage";
import { logError } from "@/lib/lazySentry";
import { toast } from "react-toastify";

const SENTRY_WHITELIST = [
  { url: /coupon/, status: 404 },
  { url: /credentials/, status: 401 },
  // 3ds default error status
  { url: /payment/, status: 402 },
  { url: /recover/, status: 404 },
  { url: "", status: 401 },
  { url: "", status: 403 },
];

export const clearSessionStorage = () => sessionStorage.removeItem("auth_token");

const getSessionToken = async () => {
  if (typeof sessionStorage === "undefined") return await getSession();

  const storageToken = JSON.parse(sessionStorage.getItem("auth_token"));

  const now = new Date();

  if (!storageToken?.expires || now.getTime() > storageToken?.expires) {
    const session = await getSession();

    session && setIsLoggedSync();

    session && sessionStorage.setItem("auth_token", JSON.stringify(session));

    return session;
  }

  storageToken && setIsLoggedSync();

  return storageToken;
};

export async function fetchV2(url, options = {}) {
  const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

  return fetch(`${baseURL}/v2${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then(async (res) => {
      const contentType = res.headers.get("content-type");

      if (!res.ok) {
        let errorData;

        // Check if response is JSON before parsing
        if (contentType && contentType.includes("application/json")) {
          errorData = await res.json();
        } else {
          errorData = await res.text(); // Handle non-JSON error responses
        }

        throw new Error(JSON.stringify({ status: res.status, error: errorData }));
      }
      return contentType && contentType.includes("application/json") ? res.json() : res.text();
    })
    .catch((err) => {
      logError(err);
      throw err;
    });
}

export async function fetchExternal(url, options = {}) {
  return fetch(url, options)
    .then((res) => res)
    .catch((err) => {
      logError(err);
      throw err;
    });
}

const api = () => {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(async (request) => {
    const session = await getSessionToken();

    if (session?.user?.accessToken) {
      try {
        request.headers.Authorization = `Token ${session.user.accessToken}`;
      } catch (err) {
        logError(err);
        toast.error("Credential error. Please sign out and sign in again.", { delay: 10000 });
        throw err;
      }
    }
    return request;
  });

  instance.interceptors.response.use(undefined, (error) => {
    if (!error?.response) {
      console.log(error);
      logError(error);
      throw new Error("Something went wront! Try again!");
    }

    const status = error.response.status;
    const pathname = error.config.url;
    const isNotWhiteListed = !SENTRY_WHITELIST.some(
      (val) => pathname.match(val.url) && val.status === status
    );
    const responseIsString = typeof error?.response?.data === "string";
    const responseIdNotFound = error?.response?.data?.detail === "Not found.";
    if (error?.response?.data?.error || responseIsString || responseIdNotFound) {
      isNotWhiteListed &&
        logError(error.response.data.error || error.response.data.detail || error.response.data);
      throw new Error(
        error.response.data.error || error.response.data.detail || error.response.data
      );
    }
    isNotWhiteListed && logError(error);
    throw error;
  });

  return instance;
};

export default api();
