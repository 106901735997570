export const STORAGE_HOW_TO_CROP_KEY = "freshener__how-to-crop-appeared";
const STORAGE_UTM_PARAMS = "freshener__utm_params";
const STORAGE_LOGGED = "freshener__logged";
const UTM_PARAMETERS = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

export function getCartOrder() {
  const reduxPersist = JSON.parse(localStorage.getItem("persist:root"));
  return reduxPersist?.Order ? JSON.parse(reduxPersist?.Order)?.items?.[0] : null;
}

export function getCartOrderObj() {
  const reduxPersist = JSON.parse(localStorage.getItem("persist:root"));
  if (reduxPersist?.Order) {
    const parsedOrder = JSON.parse(reduxPersist.Order);
    return parsedOrder.itemsById[parsedOrder.items[0]];
  }
  return null;
}

export function removeCartOrder() {
  const reduxPersist = JSON.parse(localStorage.getItem("persist:root"));
  localStorage.setItem("persist:root", JSON.stringify({ ...reduxPersist, Order: {} }));
}

export function clearReduxStore() {
  localStorage.removeItem("persist:root");
}

export function getHowToCropAppeared() {
  return Boolean(localStorage.getItem(STORAGE_HOW_TO_CROP_KEY));
}

export function setHowToCropAppeared(newState) {
  localStorage.setItem(STORAGE_HOW_TO_CROP_KEY, newState);
}

export function saveUTMParams() {
  const value = localStorage.getItem(STORAGE_UTM_PARAMS);
  if (value) return;
  const searchParams = new URLSearchParams(location.search);
  const utmData = {};
  UTM_PARAMETERS.forEach((param) => {
    if (searchParams.get(param)) utmData[param] = searchParams.get(param);
  });
  if (Object.keys(utmData)?.length)
    localStorage.setItem(STORAGE_UTM_PARAMS, JSON.stringify(utmData));
}

export function getUTMParams() {
  const value = localStorage.getItem(STORAGE_UTM_PARAMS);
  return value ? JSON.parse(value) : null;
}

export function clearUTMParams() {
  localStorage.removeItem(STORAGE_UTM_PARAMS);
}

export function setIsLoggedSync() {
  localStorage.setItem(STORAGE_LOGGED, true);
}

export function isLoggedSync() {
  return !!localStorage.getItem(STORAGE_LOGGED);
}

export function clearLoggedSync() {
  return localStorage.removeItem(STORAGE_LOGGED);
}
