import { ChevronDownIcon } from "@heroicons/react/solid";
import Image from "next/image";
import Link from "next/link";

const SideMenuCollapsableSection = ({
  items = [],
  title = "",
  expanded = false,
  onAfterClick,
  setExpanded,
}) => {
  return (
    <div>
      <span
        className="flex items-center justify-between cursor-pointer px-4 py-2"
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
      >
        <span className={`text-base font-semibold ${expanded ? "text-blue-500" : ""}`}>
          {title}
        </span>
        <ChevronDownIcon
          className={`h-6 w-6 ml-3 mt-1 ${expanded ? "rotate-180 text-blue-500" : ""}`}
        />
      </span>
      {expanded && (
        <div className={`px-2 py-1 bg-white`}>
          {items.map(({ Icon, ...item }) => (
            <Link
              key={item.name}
              href={item.href}
              {...(!item.prefetch && { prefetch: false })}
              onClick={() => onAfterClick?.()}
              className={`cursor-pointer text-sm text-black my-2 hover:text-blue-500 rounded-[6px] transition-all duration-100 px-2 py-2 flex items-center justify-left space-x-4 focus:outline-none drop-shadow-[0_1px_4px_rgba(0,0,0,0.08)] bg-white`}
            >
              {item?.image && <Image src={item.image} width={40} height={40} alt={item.name} />}
              {Icon && (
                <div className="bg-[#E9EFFD] rounded-lg flex items-center justify-center w-[40px] h-[40px]">
                  <Icon width={25} height={25} />
                </div>
              )}
              {item.imageComponent && (
                <div className="bg-[#E9EFFD] rounded-lg flex items-center justify-center w-[40px] h-[40px] p-1">
                  {item.imageComponent}
                </div>
              )}
              <span>{item.name}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideMenuCollapsableSection;
