import GooglePayOption from "@/assets/images/GooglePayOption.png";
import ApplePayOption from "@/assets/images/ApplePayOption.png";
import AmazonPayOption from "@/assets/images/AmazonPayOption.png";
import AmericanExpressOption from "@/assets/images/AmericanExpressOption.png";
import DiscoverOption from "@/assets/images/DiscoverOption.png";
import DinersClubOption from "@/assets/images/DinersClubOption.png";
import MasterCardOption from "@/assets/images/MasterCardOption.png";
import VisaOption from "@/assets/images/VisaOption.png";
import FacebookIcon from "@/assets/svg/FacebookIcon.svg";
import InstagramIcon from "@/assets/svg/InstagramIcon.svg";
import TiktokIcon from "@/assets/svg/TiktokIcon.svg";
import PinterestIcon from "@/assets/svg/PinterestIcon.svg";
import LinkedinIcon from "@/assets/svg/LinkedinIcon.svg";
import XIcon from "@/assets/svg/XIcon.svg";

export const paymentMethods = [
  { label: "Amazon Pay", image: AmazonPayOption, id: "amazon_pay" },
  {
    label: "American Express",
    image: AmericanExpressOption,
    id: "american_express",
  },
  { label: "Apple Pay", image: ApplePayOption, id: "apple_pay" },
  { label: "Diners Club", image: DinersClubOption, id: "diners_club" },
  { label: "Discover", image: DiscoverOption, id: "discover" },
  { label: "Google Pay", image: GooglePayOption, id: "google_pay" },
  { label: "Master Card", image: MasterCardOption, id: "mastercard" },

  { label: "Visa", image: VisaOption, id: "visa" },
];

export const socials = [
  {
    label: "Facebook",
    Icon: FacebookIcon,
    id: "Facebook",
    href: "https://www.facebook.com/getmakemyfreshener/",
  },
  {
    label: "Instagram",
    Icon: InstagramIcon,
    id: "Instagram",
    href: "https://www.instagram.com/makemyfreshener/",
  },
  {
    label: "TikTok",
    Icon: TiktokIcon,
    id: "Tiktok",
    href: "https://www.tiktok.com/@makemyfreshener",
  },
  {
    label: "Pinterest",
    Icon: PinterestIcon,
    id: "Pinterest",
    href: "https://www.pinterest.com/makemyfreshener",
  },
  {
    label: "LinkedIn",
    Icon: LinkedinIcon,
    id: "Linkedin",
    href: "https://www.linkedin.com/company/makemyfreshener",
  },
  {
    label: "X",
    Icon: XIcon,
    id: "x",
    href: "https://www.twitter.com/makemyfreshener",
  },
];

export const navigation = {
  shop: [
    { name: "Stock Shapes", href: "/shop/stock-shape-car-air-fresheners" },
    { name: "Custom Shapes", href: "/shop/custom-shape-car-air-fresheners" },
    {
      name: "Retail Ready Stock Shapes",
      href: "/shop/retail-ready-stock-shape-car-air-fresheners",
    },
    { name: "Sample Pack", href: "/shop/car-air-freshener-sample-pack" },
  ],
  learn: [
    { name: "Categories", href: "/learn/car-air-freshener-categories" },
    { name: "Pricing", href: "/shop" },
    { name: "Fragrances", href: "/learn/car-air-freshener-fragrances" },
  ],
  support: [
    { name: "Contact Us", href: "/support/contact", prefetch: false },
    {
      name: "Design Tips",
      href: "/support/car-air-freshener-design-tips",
      prefetch: false,
    },
    { name: "FAQ", href: "/support/car-air-freshener-faq" },
    { name: "Store Policies", href: "/support/store-policies" },
    { name: "Shipping & Production", href: "/support/shipping-production" },
  ],
  company: [
    { name: "About Us", href: "/company/about-us" },
    { name: "Reviews", href: "/company/car-air-freshener-reviews" },
    { name: "Blog", href: "/blog" },
    { name: "Terms & Conditions", href: "/company/terms-and-conditions" },
    { name: "Privacy", href: "/company/privacy-policy" },
  ],
};
