export const HeaderCardIconColored = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 224"
      fill="none"
      style={{
        height: "-webkit-fill-available",
        width: "-webkit-fill-available",
      }}
    >
      <g clipPath="url(#clip0_4440_5004)">
        <path
          d="M83.1106 3.47333H2.96777L40.0709 220.527L109.825 189.307L83.1106 3.47333Z"
          fill="#8A8A8A"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M81.6265 3.47333H2.96777L35.6186 59.9667L118.73 54.02L81.6265 3.47333Z"
          fill="#C9C9C9"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M164.794 81.2186L125.895 70.7779C118.77 68.8654 111.445 73.1013 109.536 80.239L91.354 148.212C89.4447 155.349 93.6733 162.686 100.799 164.599L139.697 175.039C146.823 176.952 154.147 172.716 156.056 165.578L174.239 97.6057C176.148 90.4679 171.919 83.1311 164.794 81.2186Z"
          fill="#1BB7EB"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M141.749 82.994C137.948 76.7919 135.391 62.2874 140.976 56.8774C147.958 50.1151 154.393 51.8423 156.002 52.2744C157.611 52.7061 162.77 59.2013 161.459 64.1026C160.166 68.9372 156.675 72.3185 151.145 76.8793"
          stroke="white"
          strokeWidth="4"
        />
        <path
          d="M142.496 86.6488C144.273 87.1258 146.1 86.0694 146.576 84.2893C147.052 82.5093 145.998 80.6796 144.221 80.2026C142.443 79.7256 140.617 80.782 140.141 82.5621C139.665 84.3422 140.719 86.1718 142.496 86.6488Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_4440_5004">
          <rect
            width="187"
            height="223"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
